import axios from '@/plugins/axios'

export default {

  async listar (dados) {
    const parametros = new URLSearchParams(dados).toString()
    return await axios.get(`situacoes-ocorrencia?${parametros}`)
  },

  async buscar (id) {
    return await axios.get(`situacoes-ocorrencia/${id}`)
  },

  async inserir (dados) {
    return await axios.post('situacoes-ocorrencia', dados)
  },

  async atualizar (id, dados) {
    return await axios.put(`situacoes-ocorrencia/${id}`, dados)
  },

  async deletar (id) {
    return await axios.delete(`situacoes-ocorrencia/${id}`)
  },

}

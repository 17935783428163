<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-voltar to="/situacoes-ocorrencia" />
      </v-col>
    </v-row>

    <situacoes-ocorrencia-campos
      :carregando-salvar="carregandoSalvar"
      :carregando-buscar="carregandoBuscar"
      :validacao-formulario="validacaoFormulario"
      :situacao-ocorrencia="situacaoOcorrencia"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
  import situacoesOcorrencia from '@/api/situacoes-ocorrencia'

  export default {

    components: {
      SituacoesOcorrenciaCampos: () => import('./SituacoesOcorrenciaCampos'),
    },

    data () {
      return {
        carregandoSalvar: false,
        carregandoBuscar: false,
        validacaoFormulario: {},
        situacaoOcorrencia: {},
      }
    },

    mounted () {
      this.buscar()
    },

    methods: {
      async buscar () {
        try {
          this.carregandoBuscar = true

          const resposta = await situacoesOcorrencia.buscar(this.$route.params.id)

          this.situacaoOcorrencia = resposta.data
        } catch (e) {
          this.$router.push('/situacoes-ocorrencia', () => this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) }))
        } finally {
          this.carregandoBuscar = false
        }
      },

      async salvar (situacao) {
        this.carregandoSalvar = true

        try {
          await situacoesOcorrencia.atualizar(situacao.id, situacao)

          this.$router.push('/situacoes-ocorrencia', () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_editar') }))
        } catch (e) {
          if (e.response.status === 422) {
            this.validacaoFormulario = this.$erroApi.validacao(e)
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoSalvar = false
        }
      },
    },

  }
</script>
